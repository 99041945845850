import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatesheetComponent } from './ratesheet.component';
import { MaterialModule } from '../../../app-material.module';
import { RequestVehicles } from '../../../Store/requestvehicles';
import { OverTime } from '../../../Store/ovetime';
import { FuelManagement } from '../../../Store/fuelmanagement';
import { BaseFareManagement } from '../../../Store/basefaremanagement';
import { LateNightOffer } from '../../../Store/latenightoffer';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
    ],
    declarations: [
        RatesheetComponent,
    ],
    providers: [
        RequestVehicles,
        OverTime,
        LateNightOffer,
        FuelManagement,
        BaseFareManagement,
    ],
    entryComponents: [RatesheetComponent],
    exports: [RatesheetComponent],
})
export class RateSheetModule { }
