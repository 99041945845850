import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClockComponent } from './clock.component';
import { MaterialModule } from '../../../app-material.module';


@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
    ],
    declarations: [
        ClockComponent,
    ],
    entryComponents: [ClockComponent],
    exports: [ClockComponent]
})
export class ClockModule { }
