import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { AuthToken } from '../Utilities/authheaders';

@Injectable()
export class RequestVehicles {

    constructor(private http: HttpClient) { }
    
    addRequestVehicle(obj, type) {
        if (type == 'edit') {
        return this.http.put(environment.api + 'request_vehicles', obj, AuthToken(JSON.parse(localStorage.getItem('token'))));
        } else {
        return this.http.post(environment.api + `request_vehicles`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))));
        }
    }

    getRequestVehicle(id) {
        return this.http.get(environment.api + `request_vehicles/${id}`, AuthToken(JSON.parse(localStorage.getItem('token'))));
    }
    getRequestVehiclesList() {
        return this.http.get(environment.api + `request_vehicles`, AuthToken(JSON.parse(localStorage.getItem('token'))));
    }
    editRequestVehicle(obj) {
        return obj
    }
    deleteRequestVehicle(id) {
        return this.http.delete(environment.api + `request_vehicles/${id}`, AuthToken(JSON.parse(localStorage.getItem('token'))));
    }
}
