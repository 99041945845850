import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthToken } from '../Utilities/authheaders';

@Injectable()
export class InformationPages {

    constructor(private http: HttpClient) { }

    getAboutus() {
        return this.http.get(environment.api + 'homepage_management/aboutus', AuthToken(JSON.parse(localStorage.getItem('token'))));
    }
    setAboutus(obj) {
        return this.http.post<any>(environment.api + 'homepage_management/aboutus', obj, AuthToken(JSON.parse(localStorage.getItem('token'))));
    }

    gettermofuse() {
        return this.http.get(environment.api + 'homepage_management/term_of_use', AuthToken(JSON.parse(localStorage.getItem('token'))));
    }
    settermofuse(obj) {
        return this.http.post<any>(environment.api + 'homepage_management/term_of_use', obj, AuthToken(JSON.parse(localStorage.getItem('token'))));
    }

    gettermsofchauffeur() {
        return this.http.get(environment.api + 'homepage_management/term_of_chauffeur', AuthToken(JSON.parse(localStorage.getItem('token'))));
    }
    settermsofchauffeur(obj) {
        return this.http.post<any>(environment.api + 'homepage_management/term_of_chauffeur', obj, AuthToken(JSON.parse(localStorage.getItem('token'))));
    }

    getprivacypolicy() {
        return this.http.get(environment.api + 'homepage_management/privacy_policy', AuthToken(JSON.parse(localStorage.getItem('token'))));
    }
    setprivacypolicy(obj) {
        return this.http.post<any>(environment.api + 'homepage_management/privacy_policy', obj, AuthToken(JSON.parse(localStorage.getItem('token'))));
    }

    getcontactus() {
        return this.http.get(environment.api + 'homepage_management/contact_us', AuthToken(JSON.parse(localStorage.getItem('token'))));
    }
    setcontactus(obj) {
        return this.http.post<any>(environment.api + 'homepage_management/contact_us', obj, AuthToken(JSON.parse(localStorage.getItem('token'))));
    }

    getRentACarForWedding() {
        return this.http.get(environment.api + 'homepage_management/rent_a_car_for_wedding', AuthToken(JSON.parse(localStorage.getItem('token'))));
    }
    setRentACarForWedding(obj) {
        return this.http.post<any>(environment.api + 'homepage_management/rent_a_car_for_wedding', obj, AuthToken(JSON.parse(localStorage.getItem('token'))));
    }

    getRentACarAtAirport() {
        return this.http.get(environment.api + 'homepage_management/rent_a_car_at_airport', AuthToken(JSON.parse(localStorage.getItem('token'))));
    }
    setRentACarAtAirport(obj) {
        return this.http.post<any>(environment.api + 'homepage_management/rent_a_car_at_airport', obj, AuthToken(JSON.parse(localStorage.getItem('token'))));
    }

}


