import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, Resolve, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../Store/authguardService';
import { Customers } from '../Store/customers';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private authenticationService: AuthenticationService,
        private cust: Customers,
        private router: Router
    ) { }

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        let accessToken = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).accessTokenExpiresAt : null
        let tokenExpire = new Date(accessToken).getTime()
        let currenttime = new Date().getTime()
        if (tokenExpire < currenttime) {
            this.router.navigate(['/admin']);
            localStorage.clear()
        } else {
            console.log('sssssssssssss')
            const isLoggedIn = await this.authenticationService.isAuthenticated();
            console.log(isLoggedIn);
            if (isLoggedIn['user'].type == 'user') {
                if (!isLoggedIn['status']) {
                    this.router.navigate(['/admin']);
                } else {
                    // this.router.navigate(['/dashboard']);
                }
                return isLoggedIn['status'];
                return true;
            } else {
                this.router.navigate(['/']);
            }
        }
        // const customers = await this.cust.getCustomersAuth()
        // console.log(customers)
        // .subscribe(res => {
        //     console.log(res)
        //     return false
        // }, err => {
        //     console.log(err)
        //     if (err.status == 401) {
        //         return true
        //     }
        // })
    }
}

@Injectable({
    providedIn: 'root'
})
export class AdminLoginResolver implements Resolve<any> {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
    ) { }

    async resolve(
        next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log(next.params);
        const isLoggedIn = await this.authenticationService.isAuthenticated();
        console.log(isLoggedIn);
        if (isLoggedIn['status'] && isLoggedIn['user'].type == 'user') {
            this.router.navigate(['/dashboard/myprofile']);
        } else { }
    }
}

@Injectable({
    providedIn: 'root'
})
export class CustomerLoginResolver implements Resolve<any> {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
    ) { }

    async resolve(
        next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log(next.params);
        const isLoggedIn = await this.authenticationService.isCustomerAuthenticated();
        console.log(isLoggedIn);
        if (isLoggedIn['status'] && isLoggedIn['user'].type == 'user') {
            this.router.navigate(['/dashboard/myprofile']);
        } else {
            // localStorage.clear()
        }
    }
}
