import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './Main/main.component';
import { AuthGuard, AdminLoginResolver, CustomerLoginResolver } from './Guards/auth.guard';
import { CustomerGuard } from './Guards/customer.guard';

const routes: Routes = [
  { path: 'resetpassword', loadChildren: () => import('./Main/newpassword/newpassword.module').then(res => res.NewpasswordModule) },
  {
    path: 'admin', loadChildren: () => import('./Main/login/login.module').then(res => res.LoginModule),
    resolve: { admin: AdminLoginResolver }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./Admin/admin.module').then(res => res.AdminModules),
    canActivate: [AuthGuard]
  },
  {
    path: '', component: MainComponent,
    children: [
      {
        path: '', loadChildren: () => import('./Template/template.module').then(res => res.TemplateModules),
        resolve: { admin: CustomerLoginResolver },
        canActivate: [CustomerGuard]
      },
      { path: 'rentacarforwedding', loadChildren: () => import('./Template/rentacarforwedding/rentacarforwedding.module').then(res => res.RentACarForWeddingModule) },
      { path: 'rentacaratairport', loadChildren: () => import('./Template/rentacaratairport/rentacaratairport.module').then(res => res.RentACarAtAirportModule) },
      { path: 'sitemap', loadChildren: () => import('./Template/sitemap/sitemap.module').then(res => res.SitemapModule) },
      { path: 'signup', loadChildren: () => import('./Main/signup/signup.module').then(res => res.SignupModule) },
      // { path: 'mlogin', loadChildren: () => import('./Main/mobilelogin/mobilelogin.module').then(res => res.MobileSignIn) },
      { path: ':type', loadChildren: () => import('./Template/vehicles/vehicles.module').then(res => res.VehiclesModules), canActivate: [CustomerGuard] },
      // { path: 'vehicles/:type', loadChildren: () => import('./Template/vehicles/vehicles.module').then(res => res.VehiclesModules), canActivate: [CustomerGuard] },

    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
