import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiscountComponent } from './discount.component';
import { MaterialModule } from '../../../app-material.module';
import { Booking } from '../../../Store/booking';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
    ],
    declarations: [
        DiscountComponent,
    ],
    providers: [
        Booking,
    ],
    entryComponents: [DiscountComponent],
    exports: [DiscountComponent],
})
export class DiscountModule { }
