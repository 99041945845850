import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GoogleLocationAPIs } from '../../../Store/googleapis'
import { MatSnackBar } from '@angular/material/snack-bar';

declare var google: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss', '../../../Utilities/button.scss']
})
export class MapComponent implements OnInit {

  latitude: number = 24.8245547; longitude: number = 67.082216;
  zoom: number = 16; coords = null; address = '';

  Locations = []; pickupplacesDiv = false;

  pickup_address = new FormControl('', [Validators.required]);

  constructor(
    private ngZone: NgZone,
    private _snackBar: MatSnackBar,
    private googleapis: GoogleLocationAPIs,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    console.log(this.data)
    if (this.data.type == 'google') {
      this.latitude = this.data.location.lat;
      this.longitude = this.data.location.lng;
      this.address = this.data.location.address;
      this.pickup_address.setValue(this.data.location.address)
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position.coords)
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.coords = {
          coords: { lat: position.coords.latitude, lng: position.coords.longitude }
        }
        console.log(this.coords)
        this.zoom = 18;

        setTimeout(() => {
          this.getLocationAddress(position.coords.latitude, position.coords.longitude, 'normal')
        }, 500);
      }, err => {
        console.log(err)
        this.coords = {
          coords: { lat: this.latitude, lng: this.longitude }
        }
        setTimeout(() => {
          this.getLocationAddress(this.latitude, this.longitude, 'normal')
        }, 500);
      });
    }
  }
  onNoClick(value): void {
    console.log(this.coords)
    this.dialogRef.close({ c: value, d: this.coords != null ? { coords: this.coords['coords'], address: this.address } : '' });
  }
  markerDragEnd($event: MouseEvent) {
    this.coords = $event;
    console.log(this.coords)
    this.getLocationAddress(this.coords['coords'].lat, this.coords['coords'].lng, 'normal')
  }
  newCenterLat: number; newCenterLng: number;
  mapReady(map) {
    map.addListener("dragend", () => {
      console.log(this.newCenterLat, this.newCenterLng)
      this.coords = {
        coords: { lat: this.newCenterLat, lng: this.newCenterLng }
      }
      this.getLocationAddress(this.newCenterLat, this.newCenterLng, 'normal')
    });
  }
  centerChange(e) {
    this.newCenterLat = e.lat
    this.newCenterLng = e.lng
  }
  googlePlaces(input, type) {
    console.log(type)
    type == 'pickup' ? this.pickupplacesDiv = true : null
    this.googleapis.googleplacesautocomplete(input).subscribe(res => {
      console.log(res)
      if (res['success']) {
        this.Locations = res['data'].predictions
        if (type == 'pickup') {
          this.pickupplacesDiv = true;
        }
        console.log(this.pickupplacesDiv)
      }
    }, err => {
      console.log(err)
    })
  }
  CurrentLocation() {
    navigator.geolocation.getCurrentPosition((position) => {
      console.log(position.coords)
      this.coords = {
        coords: { lat: position.coords.latitude, lng: position.coords.longitude }
      }
      console.log(this.coords)
      this.zoom = 18;
      setTimeout(() => {
        this.getLocationAddress(position.coords.latitude, position.coords.longitude, 'current')
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        console.log(this.latitude)
        console.log(this.longitude)
      }, 500);
    }, err => {
      console.log(err)
      this._snackBar.open('To continue, please turn on device location', 'close', {
        duration: 2000,
      });
    });
  }
  getLocationAddress(lat, lng, type) {
    const geocoder = new google.maps.Geocoder();
    const latlng = new google.maps.LatLng(lat, lng);
    const request = {
      latLng: latlng
    };
    geocoder.geocode(request, (results, status) => {
      this.ngZone.run(() => {
        console.log(results)
        if(results[0]) {
          this.pickup_address.setValue(results[0].formatted_address)
          this.address = results[0].formatted_address;
          let obj = {
            description: results[0].formatted_address,
            place_id: results[0].place_id,
          }
          if (type == 'current')
            this.PlaceLocation(obj)
        }
        console.log(this.address)
      })
    });
  }
  PlaceLocation(loc) {
    console.log(loc)
    this.Locations = []
    this.pickupplacesDiv = false;
    this.pickup_address.setValue(loc['description'])
    this.address = loc['description']
    this.googleapis.googleplacebyid(loc.place_id).subscribe(res => {
      if (res['success']) {
        let data = res['data']
        console.log(data['result'].geometry.location)
        this.latitude = data['result'].geometry.location.lat
        this.longitude = data['result'].geometry.location.lng
        this.coords = {
          coords: { lat: data['result'].geometry.location.lat, lng: data['result'].geometry.location.lng }
        }
      }
    })
  }
  Address_Length(add) {
    let maxLength = 50
    if (add.length > maxLength) {
      return `${add.slice(0, maxLength - 3)}...`;
    }
    return add;
  }

  Submit() {
    if (this.coords != null) {
      this.onNoClick('submit')
    } else { this.onNoClick(null) }
  }
}
