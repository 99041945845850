import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, Validators } from '@angular/forms';
import { Booking } from '../../../Store/booking';

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss']
})
export class DiscountComponent implements OnInit {
  submitted: any = false; spinner = false;
  couponCode = new FormControl('', [Validators.required]);

  constructor(
    private book: Booking,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    console.log(this.data)
    console.log(this.couponCode)
  }

  onNoClick(value, obj): void {
    this.dialogRef.close({ c: value, d: obj });
  }

  close() {
    this.dialogRef.close();
  }

  DiscountCode() {
    this.submitted = true;
    if (this.couponCode.valid) {
      let obj = {
        ...this.data,
        coupon_code: this.couponCode.value,
      }
      this.spinner = true;
      this.book.CouponVerification(obj).subscribe(res => {
        console.log(res)
        if (res['success'] == true) {
          this.spinner = false;
          this.onNoClick('submit', res['data'])
          this._snackBar.open('Discount Applied successfully', 'close', {
            duration: 2000,
          });
        }
      }, err => {
        this.spinner = false;
        let message = err['error'].message
        
        if(message.includes('Invalid token')) message = 'Please login first'

        this._snackBar.open(message, 'close', {
          duration: 2000,
        });
      })
      console.log(this.couponCode.value)
    }
  }
  get f() {
    return this.couponCode;
  }
}
