import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Authentication } from '../../Store/authentication';
import { routes } from '../../Utilities/template.routes';
import { LoginmodalComponent } from '../Modals/loginmodal/loginmodal.component';
import { Customers } from '../../Store/customers';
import { InformationPages } from '../../Store/aboutusadmin';
import { GoogleAnalyticsService } from 'src/app/Store/google-analytics.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  ShowHamburgerIcon = true; UserLogin = false; TemplateRoutes = routes
  username; cartNum = 6; wallet; newNotifications = 0;
  userType: any = 'customer'; userId: any = 0;
  contactDetails = {
    phone1: '',
    phone2: '',
    whatsapp_number: ''
  }

  CheckProfile = 'normal';
  constructor(
    private route: Router,
    private cust: Customers,
    private auth: Authentication,
    public dialog: MatDialog,
    private googleAnalyticsService: GoogleAnalyticsService,
    private contactdetails: InformationPages,
  ) { }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem('user'))
    this.getContactusDetails();
    if (user) {
      this.userType = user.type;
      console.log('this.userType', this.userType);

      this.CheckProfile = user['type']
    }
    this.auth.userToken.subscribe(res => {
      console.log(res);
      if (res.length != 0) {
        this.UserLogin = true;
        this.username = JSON.parse(localStorage.getItem('username'));
        const user = JSON.parse(localStorage.getItem('user'))
        let wallet = user.wallet;
        this.getNoti(user['id'])

        this.wallet = wallet == null ? 0 : wallet;
        console.log(this.username);
      } else {
        let user = JSON.parse(localStorage.getItem('user'))
        if (user) {
          let wallet = JSON.parse(localStorage.getItem('user')).wallet
          this.wallet = wallet == null ? 0 : wallet
          this.getNoti(user['id'])
          this.cust.getCustomerProfile(user['id'], '').subscribe(res => {
            console.log(res)
            if (res['success']) {
              this.wallet = res['user'].wallet_amount == null ? 0 : res['user'].wallet_amount
            }
          })
        }
        let token1 = JSON.parse(localStorage.getItem('token'))
        if (token1 != null) {
          this.UserLogin = true; this.username = JSON.parse(localStorage.getItem('username'))
        } else {
          this.UserLogin = false
        }
      }
    })
  }

  getContactusDetails() {
    this.contactdetails.getcontactus().subscribe(res => {
      console.log(res)
      if (res["data"] && res["data"]['value']) {
        let data = JSON.parse(res['data']['value'])
        console.log(data)
        this.contactDetails = data
      }
    }, err => {
      //
    });
  };

  Notifications = []
  getNoti(id) {
    let type = JSON.parse(localStorage.getItem('user')).type
    if (type == 'corporate') {
      this.cust.getCorporateNotifications(id).subscribe(res => {
        console.log(res);
        this.Notifications = res['data']
        this.newNotifications = res['new']
      })
    } else {
      this.cust.getNotifications(id).subscribe(res => {
        console.log(res);
        this.Notifications = res['data']
        this.newNotifications = res['new']
      })
    }
  }
  NotificationBadge() {
    this.newNotifications = 0;
  }
  Profile(opt) {
    let user = JSON.parse(localStorage.getItem('user'));

    if (opt == 'userprofile') {
      this.route.navigate([`/${opt}`], { queryParams: { type: 'profile' } });
    } else if (opt == 'corporateprofile') {
      this.route.navigate([`/${opt}`], { queryParams: { id: user.id } });
    } else if (opt == 'mybookings' && user.type == 'corporate') {
      this.route.navigate([`/${opt}/dashboard`], { queryParams: { id: user.id } });
    } else {
      this.route.navigate([`/${opt}`])
    }
    this.HideHamburger()
  }
  Nav(nav) {
    console.log(nav)
    let user = JSON.parse(localStorage.getItem('user'))
    if (nav == 'vehicles') {
      this.route.navigate([`${nav}`], { queryParams: { t: '', c: 'all', b: 'false' }, skipLocationChange: true })
    } else {
      this.route.navigate([`${nav}`])
    }
  }
  SignOut() {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('username')
    this.auth.loginToken('signout', '/login', '')
    this.HideHamburger()
    this.CheckProfile = 'normal';

    this.route.navigate(['/']);
  }
  LoginModal() {
    const dialogRef = this.dialog.open(LoginmodalComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      console.log('The dialog was closed');
    });
  }
  ShowHamburger() {
    this.ShowHamburgerIcon = false
  }
  HideHamburger() {
    this.ShowHamburgerIcon = true
  }
  Message(obj) {
    let maxLength = 85
    if (obj.length > maxLength) {
      return `${obj.slice(0, maxLength - 4)} ...`;
    }
    return obj;
  }
  Username(obj) {
    let maxLength = 12
    if (obj.length > maxLength) {
      return `${obj.slice(0, maxLength - 4)} ...`;
    }
    return obj;
  }

}
