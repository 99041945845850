// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  googleapikey: 'AIzaSyAxERWpyibm0zl_UEkNjzfuFPYb3ZiAats',
  // api: 'http://192.168.1.108:3000/rently/', // ahmed
  // api: 'http://192.168.0.160:3002/rently/',
  // api: 'https:/rently.pk/rently/', //live
  // api: 'https://c4495570.ngrok.io/rently/',
  // googleapikey: 'AIzaSyAzwRDOIWaOsBIXzql7u8SFIBc28Fq5gmI',
  // api: 'http://192.168.0.116:3000/rently/', // qasim
  // api: 'https://alpha.rently.pk/rently/', // alpha
  api:'http://alpha.rently.pk/rently/', // new one
  // api: 'https://www.rently.pk/rently/',
  url: 'https://www.rently.pk/',
  // api: 'http://localhost:3000/rently/',
  // api: 'http://192.168.100.7:3000/rently/',
  // api: 'https://www.rently.ticketshicket.com/rently/',
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented ox`ut in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


//509846966388585
