import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Customers } from 'src/app/Store/customers';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-doc',
  templateUrl: './doc.component.html',
  styleUrls: ['./doc.component.scss', '../../Utilities/button.scss']
})
export class DocComponent implements OnInit {

  @Input() user = {}
  @Input() duringBooking: boolean

  @Output() dataUpdated = new EventEmitter()

  processing = false

  img_list = {
    CNIC_FRONT_IMAGE    : null,
    CNIC_BACK_IMAGE     : null,
    LICENSE_FRONT_IMAGE : null,
    LICENSE_BACK_IMAGE  : null
  }

  DocForm = new FormGroup({
    cnic                : new FormControl('', [Validators.required]),
    cnic_front_image    : new FormControl('', [Validators.required]),
    cnic_back_image     : new FormControl('', [Validators.required]),
    license_number      : new FormControl('', [Validators.required]),
    license_front_image : new FormControl('', [Validators.required]),
    license_back_image  : new FormControl('', [Validators.required]),
  });

  constructor(
    private customerService : Customers,
    private _snackBar       : MatSnackBar,
  ) { }


  ngOnInit() {
    window.scroll(0, 0)
    this.setFields()
  }

  CNICDashes(nic_no) {
    if (nic_no.length == 13) {
      return nic_no.slice(0, 5) + '-' + nic_no.slice(5, 12) + '-' + nic_no.slice(12, 13)
    } else {
      return nic_no
    }
  }

  setFields(){
    this.DocForm.patchValue({
      cnic                : this.user['cnic'],
      cnic_front_image    : this.user['cnic_front_image_path'],
      cnic_back_image     : this.user['cnic_back_image_path'],
      license_number      : this.user['license_number'],
      license_front_image : this.user['license_front_image_path'],
      license_back_image  : this.user['license_back_image_path'],
    })
  }

  async handleImage(file, control, item){
    this.img_list[item] = file[0]
    control.setValue(await this.getURL(file[0]))
  }

  getURL(file) {
    return new Promise(resolve=>{
      let reader = new FileReader()
      reader.readAsDataURL(file);
      reader.onload = (e: any) => resolve(e.target.result)
    })
  }

  async Submit(){
    if(this.DocForm.invalid) return
    this.processing = true
    const formData = new FormData()

    this.img_list.CNIC_FRONT_IMAGE    != null ? formData.append('cnic_front_image', this.img_list.CNIC_FRONT_IMAGE, this.img_list.CNIC_FRONT_IMAGE.name) : ''
    this.img_list.CNIC_BACK_IMAGE     != null ? formData.append('cnic_back_image', this.img_list.CNIC_BACK_IMAGE, this.img_list.CNIC_BACK_IMAGE.name) : ''
    this.img_list.LICENSE_FRONT_IMAGE != null ? formData.append('license_front_image', this.img_list.LICENSE_FRONT_IMAGE, this.img_list.LICENSE_FRONT_IMAGE.name) : ''
    this.img_list.LICENSE_BACK_IMAGE  != null ? formData.append('license_back_image', this.img_list.LICENSE_BACK_IMAGE, this.img_list.LICENSE_BACK_IMAGE.name) : ''


    const data = {
      id             : this.user['id'],
      first_name     : this.user['first_name'],
      last_name      : this.user['last_name'],
      email          : this.user['email'],
      gender         : this.user['gender'],
      mobile_number  : this.user['mobile_number'],
      reference      : this.user['reference'],
      birth_date     : this.user['birth_date'],
      birth_month    : this.user['birth_month'],
      cnic           : this.DocForm.controls.cnic.value,
      license_number : this.DocForm.controls.license_number.value
    }

    formData.append('data', JSON.stringify(data))

    const result = await this.updateUser(formData)

    this.duringBooking && this.dataUpdated.emit(result)
    
  }

  updateUser(formData){
    return new Promise(resolve => {
      this.customerService.editCustomer(formData).subscribe(res=>{
        console.log(res);
        this.processing = false
        this._snackBar.open(res['message'], 'close', {
          duration: 2000,
        });
        resolve(res)
      }, err=>{
        console.log(err);
        this.processing = false
        this._snackBar.open(err['error'].message, 'close', {
          duration: 2000,
        });
        resolve(err)
      })
    })
  }

}
