import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthToken } from '../Utilities/authheaders';

@Injectable()
export class Corporatebooking {

  constructor(private http: HttpClient) { }
  getCorporateBookings() {
    return this.http.get(environment.api + `corporate_profiles`, AuthToken(JSON.parse(localStorage.getItem('token'))))
  }
  getCorporateBooking(corporatebookingId: any) {
    return this.http.get(environment.api + `corporate_profile/profile/${corporatebookingId}`, AuthToken(JSON.parse(localStorage.getItem('token'))))
  }
  getActiveCorporateProfiles() {
    return this.http.get(environment.api + `active_corporate_profiles`, AuthToken(JSON.parse(localStorage.getItem('token'))))
  }
  addCorporateBookings(obj) {
    return this.http.post<any>(environment.api + 'corporate_profile/sign_up', obj, AuthToken(JSON.parse(localStorage.getItem('token'))));
  }
  updateCorporateBookings(obj) {
    return this.http.put<any>(environment.api + 'corporate_profile/profile', obj, AuthToken(JSON.parse(localStorage.getItem('token'))));
  }
  stateCorporateBookings(obj) {
    return this.http.put(environment.api + `corporate_profile/change_status`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
  }
  documentupload(obj) {
    return this.http.put(environment.api + `corporate_profile/upload_document`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
  }
  removeupload(obj) {
    return this.http.put(environment.api + `corporate_profile/remove_document`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
  }
  forgetPasswordCorporateBooking(obj, type) {
    return this.http.post<any>(environment.api + `corporate_profile/forgot_password`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
  }
  setAddress(obj, status) {
    if (status == 'edit') {
      return this.http.put(environment.api + `corporate_addresses`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    } else {
      obj['is_default'] = false;
      return this.http.post(environment.api + `corporate_addresses`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
  }
  getAddress(corporate_profile_id) {
    return this.http.get(environment.api + `corporate_addresses/list/` + corporate_profile_id, AuthToken(JSON.parse(localStorage.getItem('token'))))
  }
  addressmakeDetault(obj, corporate_id) {
    return this.http.put(environment.api + 'corporate_addresses/set_is_default/' + corporate_id, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
  }
  deleteCorporateAddress(id) {
    return this.http.delete(environment.api + 'corporate_addresses/' + id, AuthToken(JSON.parse(localStorage.getItem('token'))))
  }

  addCorporateCar(obj) {
    return this.http.post<any>(environment.api + `corporate_vehicles`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
  }
  editCorporateCar(obj) {
    return this.http.put<any>(environment.api + `corporate_vehicles`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
  }
  getVehicleBaseRate() {
    return this.http.get(environment.api + `vehicle_base_fare/list`, AuthToken(JSON.parse(localStorage.getItem('token'))))
  }
  getVehicleLateNightRate() {
    return this.http.get(environment.api + `vehicle_late_night_offer/list`, AuthToken(JSON.parse(localStorage.getItem('token'))))
  }
  getVehicleOverTime() {
    return this.http.get(environment.api + `vehicle_over_time/list`, AuthToken(JSON.parse(localStorage.getItem('token'))))
  }


  dashboardcounts(obj) {
    return this.http.get(environment.api + `dashboard/corporate_profile/${obj.from}/${obj.to}/${obj.corporate_id}`, AuthToken(JSON.parse(localStorage.getItem('token'))))
  }

}
