import { NgModule, } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../app-material.module';
import { HeaderComponent } from "./header.component";
import { Authentication } from '../../Store/authentication';
import { LoginModalModule } from '../Modals/loginmodal/loginmodal.module';
import { InformationPages } from '../../Store/aboutusadmin';
import { GoogleAnalyticsService } from 'src/app/Store/google-analytics.service';
import { CustomerDocUpdateModalModule } from '../Modals/customer-doc-update/customer-doc-update.module';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        MaterialModule,
        LoginModalModule,
        CustomerDocUpdateModalModule,
        RouterModule,
    ],
    declarations: [
        HeaderComponent,
    ],
    providers: [
        Authentication,
        InformationPages,
        GoogleAnalyticsService,
    ],
    exports: [HeaderComponent]
})
export class HeaderModule { }
