import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Authentication } from '../../../Store/authentication';
import { ForgotPasswordComponent } from '../../Modals/forgot-password/forgot-password.component';
import { Customers } from '../../../Store/customers';
// import { AuthService } from "angularx-social-login";
// import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";

// declare var FB: any;

@Component({
  selector: 'app-loginmodal',
  templateUrl: './loginmodal.component.html',
  styleUrls: ['./loginmodal.component.scss']
})
export class LoginmodalComponent implements OnInit {
  hide = true; spinner = false;
  submitted: any = false;
  duringBooking = '';

  constructor(
    private _snackBar: MatSnackBar,
    private auth: Authentication,
    private route: Router,
    // private authService: AuthService,
    private cust: Customers,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  @ViewChild('btnRef', { static: true }) buttonRef: MatButton;

  loginForm = new FormGroup({
    Email: new FormControl('', [Validators.required, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)]),
    Password: new FormControl('', [Validators.required])
  });



  getErrorMessage() {
    return this.loginForm.controls.Email.hasError('required') ? 'Please enter the email address' :
      this.loginForm.controls.Email.hasError('email') ? 'Not a valid email' : '';
  }

  ngOnInit() {
    if (this.data) {
      this.duringBooking = this.data.duringBooking;
    }
    setTimeout(() => {
      this.focusOut()
    }, 400);
  }

  async LoginNow(type) {
    this.submitted = true;
    if (this.loginForm.valid) {
      let obj = {
        username: this.loginForm.controls.Email.value,
        password: this.loginForm.controls.Password.value,
        type: type,
        grant: 'password'
      };
      console.log(obj);
      this.spinner = true;
      await this.auth.userLogin(obj).subscribe(async res => {
        console.log(res);
        if (res['success'] == true) {
          this.spinner = false;
          console.log(res);
          console.log(this.route.url);
          localStorage.setItem('rental', 'false')
          if (type == 'customer') {
            await this.cust.getCustomerProfile(res['user'].id, res['user'].accessToken).toPromise().then(async customerres => {
              console.log(customerres)
              res['user']['wallet'] = await customerres['user'].wallet_amount;
              if (this.route.url == '/checkout' || this.route.url == '/custombooking' || this.route.url == '/signup') {
                this.submitted = false;
                await this.auth.loginToken(res['user'].accessToken, '', res['user']);
              } else {
                await this.auth.loginToken(res['user'].accessToken, this.route.url, res['user']);
              }
            })
          } else {
            await this.auth.loginToken(res['user'].accessToken, '', res['user']);

            this.route.navigate(['/mybookings/bookacar'])
            setTimeout(() => {
              location.reload()
            }, 500);
          }

          // Swal.fire(
          //   `Login`,
          //   `Logged In Successfully`,
          //   'success'
          // )
          this.dialogRef.close()
        } else {
          this._snackBar.open('Something went wrong', 'close', {
            duration: 2000,
          });
          this.spinner = false
        }

      }, err => {
        this._snackBar.open(err['error'].message, 'close', {
          duration: 2000,
        });
        this.spinner = false
      })
    }
  }

  // onGmailLogin() {

  //   // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);

  //   this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((userData) => {
  //     console.log('gmail', userData)

  //     let fbinfo = {
  //       googleId: userData.id,
  //       firstname: userData.firstName,
  //       lastname: userData.lastName,
  //       signupemail: userData.email
  //     }
  //     let obj = {
  //       username: userData.id,
  //       password: 'test',
  //       type: 'customer',
  //       grant: 'google'
  //     };
  //     this.spinner = true;
  //     this.UserLogin(obj, fbinfo)
  //   });
  // }

  // onFacebookLogin() {
  //   this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((userData) => {
  //     console.log('fb', userData)
  //     let fbinfo = {
  //       facebookId: userData.id,
  //       firstname: userData.firstName,
  //       lastname: userData.lastName,
  //       signupemail: userData.email
  //     }
  //     let obj = {
  //       username: userData.id,
  //       password: 'test',
  //       type: 'customer',
  //       grant: 'facebook'
  //     };
  //     this.spinner = true;
  //     this.UserLogin(obj, fbinfo)
  //   });
  // }
  ForgotPassword(type) {
    console.log('Forgot Password')
    this.dialog.open(ForgotPasswordComponent, {
      data: type,
      width: '350px',
    });
  }
  Signup(title) {

    this.dialogRef.close();

    if (title === 'User') {
      this.route.navigate(['/signup'], { queryParams: { duringbooking: this.duringBooking } });
    } else {
      this.route.navigate(['/signup-corporate'])
    }



  }

  // async UserLogin(obj, fb) {
  //   await this.auth.userLogin(obj).subscribe(res => {
  //     console.log(res);
  //     if (res['success'] == true) {
  //       this.spinner = false
  //       console.log(res);
  //       console.log(this.route.url)
  //       this.cust.getCustomerProfile(res['user'].id, res['user'].accessToken).toPromise().then(async customerres => {
  //         console.log(customerres)
  //         res['user']['wallet'] = await customerres['user'].wallet_amount
  //         if (this.route.url == '/checkout' || this.route.url == '/custombooking' || this.route.url == '/signup') {
  //           this.submitted = false;
  //           this.auth.loginToken(res['user'].accessToken, '', res['user']);
  //         } else {
  //           this.auth.loginToken(res['user'].accessToken, this.route.url, res['user']);
  //         }
  //       })
  //       this.dialogRef.close()
  //     } else {
  //       this._snackBar.open('Something went wrong', 'close', {
  //         duration: 2000,
  //       });
  //       this.spinner = false
  //     }
  //   }, async err => {
  //     console.log(err)
  //     if (err['error'].message == 'Invalid grant: user credentials are invalid' && (obj.grant == 'facebook' || obj.grant == 'google')) {
  //       await localStorage.setItem('fblogin', JSON.stringify(fb))
  //       this.dialogRef.close();
  //       setTimeout(() => {
  //         // window.location.replace('https://rently.pk/signup')
  //         this.route.navigate(['/signup'])
  //       }, 300);
  //     } else {
  //       this._snackBar.open(err['error'].message, 'close', {
  //         duration: 2000,
  //       });
  //       this.spinner = false
  //     }
  //   })
  // }
  get f() {
    return this.loginForm.controls;
  }
  focusOut() {
    // this.buttonRef.focus();
  }
}


//////////************ FaceBook Login OLD  */


    // (window as any).fbAsyncInit = function () {
    //   FB.init({
    //     appId: '437695827118518',
    //     cookie: true,
    //     xfbml: true,
    //     version: 'v5.0'
    //   });
    //   FB.AppEvents.logPageView();
    // };
    // (function (d, s, id) {
    //   var js, fjs = d.getElementsByTagName(s)[0];
    //   if (d.getElementById(id)) { return; }
    //   js = d.createElement(s); js.id = id;
    //   js.src = "https://connect.facebook.net/en_US/sdk.js";
    //   fjs.parentNode.insertBefore(js, fjs);
    // }(document, 'script', 'facebook-jssdk'));

//////////////////////************** FaceBook Login OLD */
// let that = this;
    // FB.login((response) => {
    //   if (response.authResponse) {
    //     FB.api(
    //       //'/' + response.authResponse["userID"] + ' ?fields=id,name,email/',
    //       '/me',
    //       'GET',
    //       { "fields": "id,first_name,last_name,email,picture,gender" },
    //       async function (response) {
    //         if (response && !response.error) {
    //           console.log('response', response);
    //           /* handle the result */
    //           // let userSignupObj = {
    //           //   facebook_id: response.id,
    //           //   first_name: response.first_name,
    //           //   last_name: response.last_name,
    //           //   email: response.email,
    //           //   password: null,
    //           //   mobile_number: null,
    //           //   birth_date: null,
    //           //   birth_month: null,
    //           //   reference: null,
    //           //   gender: null,
    //           //   provider: 'rently',
    //           //   platform: 'web'
    //           // };
    //           let obj = {
    //             username: response.id,
    //             password: 'test',
    //             type: 'customer',
    //             grant: 'facebook'
    //           };
    //           that.spinner = true;
    //           await that.auth.userLogin(obj).subscribe(res => {
    //             console.log(res);
    //             if (res['success'] == true) {
    //               console.log(res);
    //               that.auth.loginToken(res['user'].accessToken, '/login', res['user']);
    //               that._snackBar.open('Logged In Successfully', 'close', {
    //                 duration: 2000,
    //               });
    //               that.dialogRef.close()
    //             } else {
    //               that._snackBar.open('Something went wrong', 'close', {
    //                 duration: 2000,
    //               });
    //               that.spinner = false
    //             }
    //           }, err => {
    //             that._snackBar.open(err['error'].message, 'close', {
    //               duration: 2000,
    //             });
    //             that.spinner = false
    //           })
    //         }
    //       }
    //     );
    //     //login success
    //     //login success code here
    //     //redirect to home page
    //   }
    //   else {
    //     Swal.fire(
    //       `Error`,
    //       `User login failed`,
    //       'error'
    //     )
    //     console.log('User login failed');
    //   }
    // });
