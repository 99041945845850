import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OverTime } from '../../../Store/ovetime';
import { FuelManagement } from '../../../Store/fuelmanagement';
import { BaseFareManagement } from '../../../Store/basefaremanagement';
import { LateNightOffer } from '../../../Store/latenightoffer';
import { RequestVehicles } from 'src/app/Store/requestvehicles';

@Component({
  selector: 'app-ratesheet',
  templateUrl: './ratesheet.component.html',
  styleUrls: ['./ratesheet.component.scss']
})
export class RatesheetComponent implements OnInit {

  displayedColumns: string[] = ['title', 'price'];
  dataSource: MatTableDataSource<any>;

  displayedColumns1: string[] = ['title', 'price'];
  dataSource1: MatTableDataSource<any>;

  displayedColumns2: string[] = ['title', 'price'];
  dataSource2: MatTableDataSource<any>;

  displayedColumns3: string[] = ['title', 'price'];
  dataSource3: MatTableDataSource<any>;

  displayedColumns4: string[] = ['title', 'price'];
  dataSource4: MatTableDataSource<any>;

  constructor(
    private _snackBar: MatSnackBar,
    private requestVehicles: RequestVehicles,
    private fuel: FuelManagement,
    private base: BaseFareManagement,
    private latenight: LateNightOffer,
    private overtime: OverTime,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data) { }

    showRatesheet = false;
    showFuel = false;
    showBasefare = false;
    showLatenightOffer = false;
    showOvertime = false;
  ngOnInit() {

    if(this.data == 'ratesheet') {
      this.showRatesheet = true;
      this.getRateSheet()
    } else {
      this.showFuel = true;
      this.showBasefare = true;
      this.showLatenightOffer = true;
      this.showOvertime = true;
      this.getFuelCharges()
      this.getBasefareCharges()
      this.getLateNightCharges()
      this.getOverTimeCharges()
    }
  }

  getRateSheet() {
    this.requestVehicles.getRequestVehiclesList().subscribe(res => {
      console.log(res);
      if (res['success']) {
        this.dataSource4 = new MatTableDataSource(res['data']);
      }
    }, err => {
      console.log(err['error']);
      this._snackBar.open(err['error'].message, 'close', {
        duration: 2000,
      });
    })
  }

  getFuelCharges() {
    this.fuel.getFuels().subscribe(res => {
      console.log(res);
      if (res['success']) {
        this.dataSource = new MatTableDataSource(res['data']);
      }
    }, err => {
      console.log(err['error']);
      this._snackBar.open(err['error'].message, 'close', {
        duration: 2000,
      });
    })
  }

  getBasefareCharges() {
    this.base.getBasefares().subscribe(res => {
      console.log(res);
      if (res['success']) {
        this.dataSource1 = new MatTableDataSource(res['data']);
      }
    }, err => {
      console.log(err['error']);
      this._snackBar.open(err['error'].message, 'close', {
        duration: 2000,
      });
    })
  }

  getLateNightCharges() {
    this.latenight.getLateNightOfferList().subscribe(res => {
      console.log(res);
      if (res['success']) {
        this.dataSource2 = new MatTableDataSource(res['data']);
      }
    }, err => {
      console.log(err['error']);
      this._snackBar.open(err['error'].message, 'close', {
        duration: 2000,
      });
    })
  }

  getOverTimeCharges() {
    this.overtime.getOvertimeList().subscribe(res => {
      console.log(res);
      if (res['success']) {
        this.dataSource3 = new MatTableDataSource(res['data']);
      }
    }, err => {
      console.log(err['error']);
      this._snackBar.open(err['error'].message, 'close', {
        duration: 2000,
      });
    })
  }

}
