import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { authToken } from '../Utilities/authheaders';

@Injectable()
export class Authentication {
    private Token = new BehaviorSubject([]);
    userToken = this.Token.asObservable();

    httpOptions = {
        headers: new HttpHeaders({
            'Authorization': 'bearer' + ' ' + authToken,
        })
    };

    constructor(
        private _snackBar: MatSnackBar,
        private a_route: ActivatedRoute,
        private route: Router,
        private http: HttpClient,
    ) { }

    userRegistration(obj) {
        return this.http.post(environment.api + 'customers/sign_up', obj)
    };

    OTPVerification(obj) {
        return this.http.post(environment.api + 'otp_request', obj)
    }

    OTPCodeVerify(obj) {
        return this.http.post(environment.api + 'otp_verify', obj)
    }

    userLogin(obj) {
        console.log(obj);
        const body = new HttpParams()
            .set('username', obj.username)
            .set('password', obj.password)
            .set('type', obj.type)
            .set('grant', obj.grant);
        return this.http.post(environment.api + `oauth/login`, body, this.httpOptions)
    };
    async loginToken(status, url, user) {
        console.log(status);
        if (status == 'signout') {
            this.Token.next([])
        } else {
            console.log(user.type)

            if (user.type == 'customer') {
                localStorage.setItem('token', JSON.stringify(status));
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('username', JSON.stringify(user.name));
                this.Token.next([status]);
                console.log(this.a_route);
                let tempUrl = url.split('?');
                console.log(tempUrl);
                let QueryParams;
                await this.a_route.queryParams.subscribe(async res => {
                    QueryParams = await res
                });
                let query = Object.keys(QueryParams).length;
                let rental = localStorage.getItem('rental')
                url == '/admin' ? this.route.navigate(['/dashboard/myprofile']) : url
            } else {
                console.log('dddddddddddddddddddddddddd')
                console.log(url)
                if (url == '/admin') {
                    this.route.navigate(['/dashboard/myprofile'])
                    localStorage.setItem('token', JSON.stringify(status));
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('username', JSON.stringify(user.name));
                    this.Token.next([status]);

                } else {
                    localStorage.setItem('token', JSON.stringify(status));
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('username', JSON.stringify(user.name));
                    this.Token.next([status]);

                    this.route.navigate(['/mybookings/bookacar'], { queryParams: { id: user['id'] } })
                }
            }
        }
    }
    // setRouteParams(params, query) {
    //     console.log(params)
    //     console.log(query)
    //     if (query != 0) {
    //         return { queryParams: { t: params.t, c: params.c } }
    //     } else {
    //         if (this.route.url == '/signup') {
    //             return { queryParams: { t: '', c: 'all' } }
    //         }
    //     }
    // }
    forgotPassword(obj, type, user) {
        if (user == 'user') {
            if (type == '/admin') {
                return this.http.post(environment.api + 'users/forgot_password', { email: obj })
            } else {
                return this.http.post(environment.api + 'customers/forgot_password', { email: obj })
            }
        } else {
            return this.http.post(environment.api + `corporate_profile/forgot_password`, { email: obj })
        }
    }
    newPassword(obj, id) {
        if (id == 'user') {
            return this.http.post(environment.api + 'users/change_password', obj)
        } else if (id == 'corporate_profile') {
            return this.http.post(environment.api + 'corporate_profile/change_password', obj)
        } else {
            return this.http.post(environment.api + 'customers/change_password', obj)
        }
    }
}
