import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'angularboiler';

  ngOnInit() {
    // const splashScreen: HTMLElement = document.getElementById('Loading');
    // if (splashScreen) {
    //   setTimeout(() => {
    //     splashScreen.remove();
    //   }, 500);
    // }
  }
}
