import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthToken } from '../Utilities/authheaders';
import { innerRights } from 'src/app/Utilities/userroles';

@Injectable()
export class RolesAuthorization {

    public role = new BehaviorSubject<Array<Object>>([]);
    access = this.role.asObservable();

    constructor(private http: HttpClient) { }

    getRight(flag) {
        let right;
        let data = JSON.parse(localStorage.getItem('access')) //btoa()
        if(data) {
            data.map(res => {
                if (res['module'].module_name == flag) {
                    right = res['is_allowed']
                }
            })
        }
        return right
    }

    setRights(permissions) {
        this.role.next(permissions)
    }


    getAdminList() {
        return this.http.get(environment.api + `users`, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }

    getAdmin(id) {
        return this.http.get(environment.api + `users/${id}`, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }

    addAdmin(users) {
        return this.http.post(environment.api + `users/register`, users, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }

    updateAdmin(users) {
        return this.http.put(environment.api + `users/profile`, users, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }

    adminStatusChange(users) {
        return this.http.put(environment.api + `users/change_status`, users, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }

    getModulesList() {
        return this.http.get(environment.api + `modules`, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
}
