import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Resolve, } from '@angular/router';
import { AuthToken } from '../Utilities/authheaders';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService implements Resolve<any> {

    constructor(private http: HttpClient) { }

    resolve() {
        return 'danial'
    }

    // Checking if user is authenticated
    public async isAuthenticated() {
        if (JSON.parse(localStorage.getItem('token')) == null) { return { status: false, user: '' } } else {
            try {
                // const response = await this.http.get(environment.api + `customers`, AuthToken(JSON.parse(localStorage.getItem('token'))))
                // console.log(response)
                // let customers = await response.toPromise().then(res => {
                //     console.log(res)
                //     return true
                // }, err => {
                //     return false
                // })
                return { status: true, user: JSON.parse(localStorage.getItem('user')) }
            } catch (error) {
                return { status: false, user: '' };
            }
        }
    }
    public async isCustomerAuthenticated() {
        if (JSON.parse(localStorage.getItem('token')) == null) { return { status: false, type: 'local' }; } else {
            try {
                // const response = await this.http.get(environment.api + `customers`, AuthToken(JSON.parse(localStorage.getItem('token'))))
                // console.log(response)
                // let customers = await response.toPromise().then(res => {
                //     console.log(res)
                //     return { status: false, type: 'auth' }
                // }, err => {
                //     return { status: true, type: 'auth' }
                // })
                return { status: true, user: JSON.parse(localStorage.getItem('user')) }
            } catch (error) {
                return { status: false, user: JSON.parse(localStorage.getItem('user')) }
            }
        }
    }
}
