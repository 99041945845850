import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { MapComponent } from './map.component';
import { MaterialModule } from '../../../app-material.module';
import { environment } from '../../../../environments/environment';
import { GoogleLocationAPIs } from '../../../Store/googleapis'

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        AgmCoreModule.forRoot({
            apiKey: environment.googleapikey,
            language: 'en',
        }),
    ],
    declarations: [
        MapComponent,
    ],
    providers: [
        GoogleLocationAPIs
    ],
    entryComponents: [MapComponent],
})
export class MapModule { }
