import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Authentication } from '../../../Store/authentication';
import { LoginmodalComponent } from './loginmodal.component';
import { MaterialModule } from '../../../app-material.module';
import { ForgotPasswordModule } from '../forgot-password/forgot-password.module';
import { Customers } from '../../../Store/customers';
// import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
// import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";

// const config = new AuthServiceConfig([
//     {
//         id: GoogleLoginProvider.PROVIDER_ID,
//         provider: new GoogleLoginProvider("612652402976-bej964rc8h3rbgai3fr6lkhnm3ie5ots.apps.googleusercontent.com")
//     },
//     {
//         id: FacebookLoginProvider.PROVIDER_ID,
//         provider: new FacebookLoginProvider('509846966388585')
//     }
// ]);

// export function provideConfig() {
//     return config;
// }

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ForgotPasswordModule,
        // SocialLoginModule,
    ],
    declarations: [
        LoginmodalComponent,
    ],
    entryComponents: [
        LoginmodalComponent
    ],
    providers: [
        Authentication,
        Customers,
        // {
        //     provide: AuthServiceConfig,
        //     useFactory: provideConfig
        // },
    ]
})
export class LoginModalModule { }
