import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthToken } from '../Utilities/authheaders';

@Injectable()
export class Settings {

    constructor(private http: HttpClient) { }

    setSettings(obj) {
        return this.http.post(environment.api + `settings`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    setShortRentalSettings(obj) {
        return this.http.post(environment.api + `settings/short_rental`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    getSettings(value) {
        return this.http.get(environment.api + `settings/${value}`, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    adminsettings(obj) {
        return this.http.post(environment.api + `settings/admin`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
}
