import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapModule } from '../../Template/ModalBox/map/map.module';
import { MaterialModule } from '../../app-material.module';
import { Cars } from '../../Store/cars';
import { Booking } from '../../Store/booking';
import {
    IgxTimePickerModule,
    IgxInputGroupModule,
    IgxIconModule,
    IgxToastModule,
} from "igniteui-angular";
import { GoogleLocationAPIs } from '../../Store/googleapis';
import { DiscountModule } from '../../Template/ModalBox/discount/discount.module';
import { Customers } from '../../Store/customers';

import { RateSheetModule } from '../Modals/ratesheet/ratesheet.module';
import { ClockModule } from '../Modals/clock/clock.module';
import { CarImagesModule } from '../../Admin/Modals/carimages/carimages.module';
import { Settings } from '../../Store/settings';

import { OverTime } from '../../Store/ovetime';
import { FuelManagement } from '../../Store/fuelmanagement';
import { LateNightOffer } from '../../Store/latenightoffer';
import { BaseFareManagement } from '../../Store/basefaremanagement';
import { Taxes } from '../../Store/taxes';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { GoogleAnalyticsService } from 'src/app/Store/google-analytics.service';
import { DocComponent } from './doc.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        IgxTimePickerModule,
        IgxInputGroupModule,
        IgxIconModule,
        IgxToastModule,
        MapModule,
        DiscountModule,
        CarImagesModule,
        ClockModule,
        NgxMaterialTimepickerModule,
        RateSheetModule,
    ],
    declarations: [
        DocComponent,
    ],
    entryComponents: [
    ],
    providers: [
        Cars,
        Booking,
        Settings,
        Customers,
        GoogleLocationAPIs,
        GoogleAnalyticsService,
        Taxes,
        OverTime,
        FuelManagement,
        LateNightOffer,
        BaseFareManagement,
    ],
    exports: [
        DocComponent
    ]
})
export class DocModule { }
