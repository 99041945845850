import { Injectable } from '@angular/core';
declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsService {

    constructor() { }

    eventEmitter(
        eventName: string,
        eventCategory: string,
        eventLable: string,
        value: string) {
        gtag('event', eventName, {
            'event_category': eventCategory,
            'event_label': eventLable,
            'value': value,
        })
    }
}