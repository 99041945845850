import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { AuthToken } from '../Utilities/authheaders';
import { Router } from '@angular/router';

@Injectable()
export class Customers {

    constructor(
        private route: Router,
        private http: HttpClient,
    ) { }

    getCustomers() {
        return this.http.get(environment.api + 'customers', AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    getActiveCustomers() {
        return this.http.get(environment.api + 'active_customers', AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    async getCustomersAuth() {
        this.http.get(environment.api + 'customers', AuthToken(JSON.parse(localStorage.getItem('token')))).subscribe(res => {
        }, err => {
            console.log(err)
            if (err.status == 401) {
                localStorage.clear()
                this.route.navigate(['/admin'])
            }
        })
    }
    getCustomerProfile(uid, token) {
        return this.http.get(environment.api + `customers/profile/${uid}`, AuthToken(token == '' ? JSON.parse(localStorage.getItem('token')) : token))
    }
    setStatus(obj, type) {
        return this.http.put(environment.api + `${type}s/change_status`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    editCustomer(obj) {
        return this.http.put(environment.api + `customers/profile`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    setAddress(obj, status) {
        if (status == 'edit') {
            return this.http.put(environment.api + `customer_addresses`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
        } else {
            obj['is_default'] = false;
            return this.http.post(environment.api + `customer_addresses`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
        }
    }
    getAddresses(uid) {
        return this.http.get(environment.api + 'customer_addresses/list/' + uid, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    getAddressesCorporate(uid) {
        return this.http.get(environment.api + 'customer_addresses/list/' + uid, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    addressmakeDetault(obj, customer_id) {
        return this.http.put(environment.api + 'customer_addresses/set_is_default/' + customer_id, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    deleteCustAddress(id) {
        return this.http.delete(environment.api + 'customer_addresses/' + id, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    getNotifications(uid) {
        return this.http.get(environment.api + 'customer_sms_history/' + uid, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    getCorporateNotifications(uid) {
        return this.http.get(environment.api + 'corporate_sms_history/' + uid, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
}
