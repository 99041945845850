import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { Customers } from '../../../Store/customers';

@Component({
  selector: 'app-customer-doc-update',
  templateUrl: './customer-doc-update.component.html',
  styleUrls: ['./customer-doc-update.component.scss']
})
export class CustomerDocUpdateComponent implements OnInit {
  
  user

  constructor(
    private cust      : Customers,
    public  dialog    : MatDialog,
    public  dialogRef : MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data) { }


  async ngOnInit() {
    await this.getUserProfile()
  }

  getUserProfile(){
    const currentUser = JSON.parse(localStorage.getItem('user'))
    return new Promise(resolve=>{
      this.cust.getCustomerProfile(currentUser['id'], currentUser['accessToken']).subscribe((res: any)=>{
        console.log(res);
        this.user = res.user
        resolve(true)
      }, err =>{
        console.log(err);
        resolve(false)
      })
    })
  }

  userUpdated(response){
    this.dialogRef.close(response)
  }


}