import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class GoogleLocationAPIs {

    constructor(private http: HttpClient) { }

    googleplacesautocomplete(input) {
        return this.http.get(`${environment.api}google_api/autocomplete/${input}`)
        // return this.http.get(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${input}&types=establishment&location=37.76999,-122.44696&radius=500&key=${environment.googleapikey}`)
    }
    googleplacebyid(id) {
        return this.http.get(`${environment.api}google_api/place/${id}`)
        // return this.http.get(`https://maps.googleapis.com/maps/api/place/details/json?key=${environment.googleapikey}&placeid=${id}`)
    }

}