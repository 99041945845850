import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthToken } from '../Utilities/authheaders';

@Injectable()
export class Cars {

    constructor(private http: HttpClient) { }

    setCars(obj, type) {
        console.log(obj)
        if (type == 'edit') {
            return this.http.put(environment.api + 'vehicles', obj, AuthToken(JSON.parse(localStorage.getItem('token'))));
        } else {
            return this.http.post(environment.api + 'vehicles', obj, AuthToken(JSON.parse(localStorage.getItem('token'))));
        }
    }
    getCars() {
        return this.http.get(environment.api + 'vehicles', AuthToken(JSON.parse(localStorage.getItem('token'))));
    }
    getCarbyModelAndBrand(obj) {
        return this.http.post(environment.api + `vehiclebymodelandbrand`, obj);
    }
    getCarsbyCategory(obj) {
        return this.http.get(environment.api + `vehiclesbycategory/${obj}`, AuthToken(JSON.parse(localStorage.getItem('token'))));
    }
    getCarsbyFilter(obj) {
        return this.http.post(environment.api + `vehicleswithfilters`, obj);
    }
    getCar(id) {
        return this.http.get(environment.api + `vehicles/${id}`, AuthToken(JSON.parse(localStorage.getItem('token'))));
    }
    getCorporateCar(id) {
        return this.http.get(environment.api + `corporate_vehicles/${id}`, AuthToken(JSON.parse(localStorage.getItem('token'))));
    }
    setCorporateVehicle(obj, type) {
        if (type == 'edit') {
            return this.http.put(environment.api + 'corporate_vehicles', obj, AuthToken(JSON.parse(localStorage.getItem('token'))));
        } else {
            return this.http.post(environment.api + 'corporate_vehicles', obj, AuthToken(JSON.parse(localStorage.getItem('token'))));
        }
    }
}