import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, Validators } from '@angular/forms';
import { Authentication } from '../../../Store/authentication';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  spinner = false;

  constructor(
    private route: Router,
    private _snackBar: MatSnackBar,
    private auth: Authentication,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  email = new FormControl('', [Validators.required, Validators.email])

  getErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' :
      this.email.hasError('email') ? 'Not a valid email' :
        '';
  }
  ngOnInit() {
  }

  Submit() {
    console.log(this.route.url)
    if (this.email.valid) {
      this.spinner = true;
      this.ForgortPassword(this.email.value, this.route.url)
    }
  }

  ForgortPassword(value, type) {
    console.log(this.data)
    this.auth.forgotPassword(value, type, this.data).subscribe(res => {
      console.log(res)
      if (res['success'] == true) {
        this._snackBar.open(res['message'], 'close', {
          duration: 2000,
        });
        this.spinner = false
        this.dialogRef.close()
      }
    }, err => {
      console.log(err)
      this._snackBar.open(err['error'].message, 'close', {
        duration: 2000,
      });
      this.spinner = false
    })
  }
}
