import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import Swal from "sweetalert2";

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss']
})
export class ClockComponent implements OnInit {

  ampmArray = [
    'AM',
    'PM',
  ]

  hoursArray = []; minutesArray = []; ampm = new Date().getHours() >= 12 ? 'PM' : 'AM';
  hours = new Date().getHours() > 12 ? new Date().getHours() - 12 : new Date().getHours();
  minutes = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();

  time;
  selecthours = new FormControl('', [Validators.required]);
  selecttime = new FormControl('', [Validators.required]);
  // new Date('26-Apr-2020').setHours(10, 33);

  constructor(
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data) { }


  ngOnInit() {
    console.log(this.data)
    this.time = new Date(this.data.date).getTime()
    this.setTime()
    this.makeHoursnMinutes()
  }
  setTime() {
    this.ampm = new Date(this.data.time).getHours() >= 12 ? 'PM' : 'AM';
    this.hours = new Date(this.data.time).getHours() > 12 ? new Date(this.data.time).getHours() - 12 : new Date(this.data.time).getHours();
    this.minutes = new Date(this.data.time).getMinutes() < 10 ? '0' + new Date(this.data.time).getMinutes() : new Date(this.data.time).getMinutes();

    this.selecthours.setValue(this.hours)
    this.selecttime.setValue(this.minutes)

    console.log(this.selecthours)
    console.log(this.selecttime)

    this.AMPM(this.ampm == 'AM' ? this.ampmArray[0] : this.ampmArray[1])
  }
  makeHoursnMinutes() {
    this.hoursArray = []
    this.minutesArray = []
    for (var i = 0; i <= 59; i++) {
      if (i <= 12 && i > 0) {
        this.hoursArray.push(i)
        // this.hoursArray.push({ value: i, status: 'deactive' })
      }
      this.minutesArray.push(i)
      // this.minutesArray.push({ value: i, status: 'deactive' })
    }
    // this.hoursArray[Number(this.hours) - 1]['status'] = 'active';
    // this.minutesArray[Number(this.minutes)]['status'] = 'active';
  }
  // Hours(h) {
  //   console.log(h)
  //   this.hours = h.value <= 10 ? h.value : h.value
  //   this.makeHoursnMinutes()
  // }
  // Minutes(m) {
  //   console.log(m)
  //   this.minutes = m.value < 10 ? '0' + m.value : m.value
  //   this.makeHoursnMinutes()
  // }
  Hours(h) {
    console.log(h)
    this.hours = h <= 10 ? h : h
    this.makeHoursnMinutes()
  }
  Minutes(m) {
    console.log(m)
    this.minutes = m < 10 ? '0' + m : m
    this.makeHoursnMinutes()
  }
  submit() {
    let date = new Date(this.data.date).setHours(Number(this.ampm == 'PM' ? this.hours + 12 : this.hours), Number(this.minutes))
    console.log(date)
    console.log(this.time)
    new Date(this.time)
    if (this.time > date) {
      // this._snackBar.open('Time not Valid', 'close', {
      //   duration: 2000,
      // });
      Swal.fire(
        'Error',
        'Selected time is lesser than current time please update time to proceed',
        'warning'
      )
    } else {
      this.onNoClick({ type: 'submit', value: new Date(date) })
      console.log('continuee')
    }
  }

  AMPM(value) {
    this.ampm = value
    console.log(value)
  }
  getColor(value) {
    return value.status == 'active' ? '#f5f5f5' : 'none'
  }
  onNoClick(value): void {
    this.dialogRef.close(value);
  }
}
