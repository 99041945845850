import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { AuthToken } from '../Utilities/authheaders';

@Injectable()
export class OverTime {

    constructor(private http: HttpClient) { }

    getOvertimeList() {
        return this.http.get(environment.api + `vehicle_over_time/list`, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    getBasefarebyType(type) {
        return this.http.get(environment.api + `vehicle_over_time/list/${type}`, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    setOverTime(obj, type) {
        if (type == 'edit') {
            return this.http.put(environment.api + `vehicle_over_time`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
        } else {
            return this.http.post(environment.api + `vehicle_over_time`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
        }
    }
    setStatus(obj) {
        return this.http.put(environment.api + `vehicle_over_time`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
}
