import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http'
import { MaterialModule } from '../../app-material.module';
import { routing } from "./login.routing";
import { LoginComponent } from './login.component';
import { HeaderModule } from '../header/header.module';
import { Authentication } from '../../Store/authentication';
// import { ForgotPasswordComponent } from '../Modals/forgot-password/forgot-password.component';
import { AdminLoginResolver } from '../../Guards/auth.guard';
import { AuthenticationService } from '../../Store/authguardService';
import { RolesAuthorization } from 'src/app/Store/access_rights';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        routing,
        HeaderModule,
        HttpClientModule,
    ],
    providers: [
        Authentication,
        RolesAuthorization,
        AdminLoginResolver,
        AuthenticationService,
    ],
    declarations: [
        // ForgotPasswordComponent,
        LoginComponent,
    ],
    exports: [LoginComponent]
})
export class LoginModule { }
