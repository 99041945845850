import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { AuthToken } from '../Utilities/authheaders';

@Injectable()
export class Taxes {

    constructor(private http: HttpClient) { }

    getTaxesList() {
        return this.http.get(environment.api + `taxes/list`, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    setTaxes(obj, type) {
        if (type == 'edit') {
            return this.http.put(environment.api + `taxes`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
        } else {
            return this.http.post(environment.api + `taxes`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
        }
    }
    setStatus(obj) {
        return this.http.put(environment.api + `taxes`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
}
