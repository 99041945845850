import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Authentication } from '../../Store/authentication';
import { ForgotPasswordComponent } from '../Modals/forgot-password/forgot-password.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RolesAuthorization } from 'src/app/Store/access_rights';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  spinner = false;
  constructor(
    private _snackBar: MatSnackBar,
    private auth: Authentication,
    private route: Router,
    public dialog: MatDialog,
    private role: RolesAuthorization,
  ) { }

  loginForm = new FormGroup({
    Email: new FormControl('', [Validators.required, Validators.pattern('')]),
    Password: new FormControl('', [Validators.required]) //, Validators.minLength(6), Validators.maxLength(128)
  });

  checked = true; hide = true; checkAdmin = true

  getErrorMessage() {
    return this.loginForm.controls.Email.hasError('required') ? 'Please enter the email address' :
      this.loginForm.controls.Email.hasError('email') ? 'Not a valid email' : '';
  }

  ngOnInit() {
    window.scroll(0, 0);

    console.log(this.route.url)
    if (this.route.url == '/admin') {
      this.checkAdmin = false
    } else {
      this.checkAdmin = true
    }
  }

  LoginNow() {
    if (this.loginForm.valid) {
      let obj = {
        username: this.loginForm.controls.Email.value,
        password: this.loginForm.controls.Password.value,
        type: this.checkAdmin == false ? 'user' : 'customer',
        grant: 'password'
      };
      this.spinner = true;
      console.log(obj);
      this.auth.userLogin(obj).subscribe(res => {
        console.log(res);
        if (res['success'] == true) {
          this.auth.loginToken(res['user'].accessToken, this.route.url, res['user'])
          // this.role.setRights(res['user'].id)
          setTimeout(() => {
            this._snackBar.open('Logged In Successfully', 'close', {
              duration: 2000,
            });
          }, 2000);
        } else {
          this._snackBar.open('Something went wrong', 'close', {
            duration: 2000,
          });
          this.spinner = false
        }
      }, err => {
        this._snackBar.open(err['error'].message, 'close', {
          duration: 2000,
        });
        this.spinner = false
      })
    }
  }
  ForgotPassword() {
    console.log('Forgot Password')
    this.dialog.open(ForgotPasswordComponent, {
      width: '350px',
    });
  }
}
