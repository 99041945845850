import { HttpHeaders } from '@angular/common/http'

export function AuthToken(token) {
    let httpOptions = {
        headers: new HttpHeaders({
            'Authorization': 'Bearer' + ' ' + token,
        })
    };
    return httpOptions
}

export const authToken = '805fdaadadf01094aa0d3823ce2f9452a76d8fee';