import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthToken } from '../Utilities/authheaders';

@Injectable()
export class Booking {

    constructor(private http: HttpClient) { }

    getGeneralBookings() {
        return this.http.get(environment.api + `general_bookings`, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    getBookingsbystatus(type, obj) {
        return this.http.get(environment.api + `bookings_by_status/${type}/${obj.from}/${obj.to}`, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    getCustomBookings() {
        return this.http.get(environment.api + `custom_bookings`, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    getBookingEntrybyId(id) {
        return this.http.get(environment.api + `bookings/${id}`, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    getBookingsforPayments() {
        return this.http.get(environment.api + `general_bookings_for_payment`, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    getUserBookings(id, type) {
        return this.http.get(environment.api + `customer_bookings/${id}/${type}`, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    getCustomersGeneralBookings(id) {
        return this.http.get(environment.api + `customer_bookings/general/${id}`, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    setKilometers(obj, type) {
        if (type == 'corporate') {
            return this.http.put(environment.api + `corporate_booking_details`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
        } else {
            return this.http.put(environment.api + `booking_details`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
        }
    }
    setBookingEntry(obj, type) {
        return this.http.post(environment.api + `bookings/${type}`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    ConfirmBookingwithDriver(obj) {
        return this.http.put(environment.api + `bookings/confirm`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    CompleteBooking(obj) {
        return this.http.put(environment.api + `bookings/complete`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    CancelBooking(obj, type) {
        if (type == 'corporate') {
            return this.http.put(environment.api + `corporate_bookings/cancel`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
        } else {
            return this.http.put(environment.api + `bookings/cancel`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
        }
    }
    CouponVerification(obj) {
        return this.http.post(environment.api + `discount_coupon_management/check_availability`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    DriverRating(obj) {
        return this.http.post(environment.api + `customer_reviews`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    updatebooking(obj, type) {
        if (type == 'corporate') {
            return this.http.put(environment.api + `corporate_booking_details/update_time`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
        } else {
            return this.http.put(environment.api + `booking_details/update_time`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
        }
    }
    dayDelete(id, type) {
        if (type == 'corporate') {
            return this.http.delete(environment.api + `corporate_booking_details/${id}`, AuthToken(JSON.parse(localStorage.getItem('token'))))
        } else {
            return this.http.delete(environment.api + `booking_details/${id}`, AuthToken(JSON.parse(localStorage.getItem('token'))))
        }
    }
    //day end
    endEnd(obj, type) {
        if (type == 'corporate') {
            return this.http.put(environment.api + `corporate_booking_details_day_complete`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
        } else {
            return this.http.put(environment.api + `booking_details_day_complete`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
        }
    }
    //Discount
    addDiscount(obj, type) {
        if (type == 'corporate') {
            return this.http.put(environment.api + `corporate_booking_details_add_discount`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
        } else if(type == 'self_drive'){
            return this.http.put(environment.api + `bookings/add_discount`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
        } else {
            return this.http.put(environment.api + `booking_details_add_discount`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
        }
    }

    addExtensionSelfDrive(obj){
        return this.http.put(environment.api + `bookings/self_drive/extend `, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }


    //Coporate Booking Apis
    getGeneralCorporateBookings() {
        return this.http.get(environment.api + `general_corporate_bookings`, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    getCorporateBookings(id, type) {
        return this.http.get(environment.api + `corporate_bookings_by_profile/${id}/${type}`, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    getCorporateBookingEntrybyId(id) {
        return this.http.get(environment.api + `corporate_bookings/${id}`, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    setCorporateBookingEntry(obj, type) {
        return this.http.post(environment.api + `corporate_bookings/${type}`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    CompleteCorporateBooking(obj) {
        return this.http.put(environment.api + `corporate_bookings/complete`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    ConfirmCorporateBookingwithDriver(obj) {
        return this.http.put(environment.api + `corporate_bookings/confirm`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    CancelCorporateBooking(obj) {
        return this.http.put(environment.api + `corporate_bookings/cancel`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
}
