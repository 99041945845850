import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http'
import { MaterialModule } from '../../../app-material.module';
import { ForgotPasswordComponent } from './forgot-password.component';
import { Authentication } from '../../../Store/authentication';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        HttpClientModule,
    ],
    providers: [
        Authentication,
    ],
    declarations: [
        ForgotPasswordComponent,
    ],
    entryComponents: [
        ForgotPasswordComponent,
    ]
})
export class ForgotPasswordModule { }
