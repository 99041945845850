import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Corporatebooking } from '../Store/corporatebooking';
import { Customers } from '../Store/customers';
import Swal from "sweetalert2";

@Injectable({
    providedIn: 'root'
})
export class CustomerGuard implements CanActivate {
    constructor(
        private cust: Customers,
        private corp: Corporatebooking,
        private route: Router,
    ) { }

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        let user = JSON.parse(localStorage.getItem('user'))
        console.log(user)
        if (user == null) { localStorage.removeItem('user') } else {
            if (user['id'] && user['type'] == 'customer') {
                await this.cust.getCustomerProfile(user['id'], '').subscribe(res => {
                    console.log(res)
                    if (res['success']) {
                        if (res['user'].status == 'disabled') {
                            console.log('ssssssss')
                            localStorage.clear()
                            Swal.fire(
                                'Warning',
                                'Your profile has been disable by admin',
                                'warning'
                            )
                            setTimeout(() => {
                                location.reload()
                            }, 1500);
                        } else {
                            console.log('Customer Active Success')
                        }
                    } else {
                        console.log('Do Logout')
                    }
                }, err => {
                    console.log(err['error'])
                    if (err['error'].status == 401) {
                        localStorage.clear()
                        // Swal.fire(
                        //     'Warning',
                        //     `${err['error'].message}`,
                        //     'warning'
                        // )
                        setTimeout(() => {
                            location.reload()
                        }, 1500);
                    }
                })
            } else if (user['id'] && user['type'] == 'corporate') {
                // if (next['url'].length != 0) {
                //     if (next['url'][0].path == 'vehicles') {
                //         this.route.navigate(['/mybookings/bookacar'])
                //         return false
                //     }
                // }
                await this.corp.getCorporateBooking(user['id']).subscribe(res => {
                    console.log(res)
                    if (res['success']) {
                        if (res['user'].status == 'disabled') {
                            console.log('ssssssss')
                            localStorage.clear()
                            Swal.fire(
                                'Warning',
                                'Your profile has been disable by admin',
                                'warning'
                            )
                            setTimeout(() => {
                                location.reload()
                            }, 1500);
                        } else {
                            console.log('Customer Active Success')
                        }
                    } else {
                        console.log('Do Logout')
                    }
                }, err => {
                    console.log(err['error'])
                    if (err['error'].status == 401) {
                        localStorage.clear()
                        // Swal.fire(
                        //     'Warning',
                        //     `${err['error'].message}`,
                        //     'warning'
                        // )
                        setTimeout(() => {
                            location.reload()
                        }, 1500);
                    }
                })
            }
        }
        return true;
        return true;
    }
}
