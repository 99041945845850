import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from './app-material.module'
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { Authentication } from './Store/authentication';
import { AppComponent } from './app.component';
import { MainComponent } from './Main/main.component';
import { LoginModule } from './Main/login/login.module';
import { FooterModule } from './Main/footer/footer.module';
import { HeaderModule } from './Main/header/header.module';

import { AuthGuard } from './Guards/auth.guard';
import { Corporatebooking } from './Store/corporatebooking';
import { RolesAuthorization } from './Store/access_rights';
// import { LoginComponent } from './CommonComponents/login/login.component';

import { Settings } from './Store/settings';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    // LoginComponent,
  ],
  imports: [
    // BrowserModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    LoginModule,
    FooterModule,
    HeaderModule,
  ],
  providers: [AuthGuard, RolesAuthorization, Authentication, Corporatebooking, Settings,],
  bootstrap: [AppComponent]
})
export class AppModule { }
