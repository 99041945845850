import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { AuthToken } from '../Utilities/authheaders';

@Injectable()
export class FuelManagement {

    constructor(private http: HttpClient) { }

    getFuels() {
        return this.http.get(environment.api + `vehicle_fuel_management/list`, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    getFuelbyType(type) {
        return this.http.get(environment.api + `vehicle_fuel_management/list/${type}`, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
    setFuels(obj, type) {
        if (type == 'edit') {
            return this.http.put(environment.api + `vehicle_fuel_management`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
        } else {
            return this.http.post(environment.api + `vehicle_fuel_management`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
        }
    }
    setStatus(obj) {
        return this.http.put(environment.api + `vehicle_fuel_management`, obj, AuthToken(JSON.parse(localStorage.getItem('token'))))
    }
}
