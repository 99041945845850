import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Authentication } from '../../../Store/authentication';
import { CustomerDocUpdateComponent } from './customer-doc-update.component';
import { MaterialModule } from '../../../app-material.module';
import { ForgotPasswordModule } from '../forgot-password/forgot-password.module';
import { Customers } from '../../../Store/customers';
import { DocModule } from 'src/app/CommonComponents/doc/doc.module';


@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ForgotPasswordModule,
        DocModule
    ],
    declarations: [
        CustomerDocUpdateComponent,
    ],
    entryComponents: [
        CustomerDocUpdateComponent
    ],
    providers: [
        Authentication,
        Customers,
    ],
})
export class CustomerDocUpdateModalModule { }
