import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CarsComponent } from '../../cars/cars.component';

@Component({
  selector: 'app-carimages',
  templateUrl: './carimages.component.html',
  styleUrls: ['./carimages.component.scss']
})
export class CarimagesComponent implements OnInit {
  sliderImages = []; ImageName;

  constructor(public dialogRef: MatDialogRef<CarsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit() {
    this.ImageName = this.data.name;
    this.sliderImages = this.data.images;
    console.log(this.data)
  }
}
